import Player from "./components/player/player";

function App() {
    return(
        <main>
            <Player />
        </main>
    )
}
export default App;
